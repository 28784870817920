.background {
  padding-bottom: 10px;  
}

.pink-background {
  background-color: #ffedee;
  padding: 7%;
  margin: 1%;
  border: 1px solid;
  border-radius: 10px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
}

.pink-background h1 {
  margin: 3% 0 9% 0;
}

.background-2 {
  background-color: #fbefea;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: flex-start;
  padding: 1.5% 5%;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
  transition: box-shadow 0.3s ease-in-out;
}

.background-2:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
}

.background-3 {
  background-color: #e5f3f7;
  padding: 1.5% 5%;
  border: 1px solid;
  border-radius: 10px;
}

.page-body {
  margin: 1%;
}

.background-2 h1 {
  text-align: center;
}

body {
  color: #030a24;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

header {
  font-size: 40px;
}

#nav {
  position: absolute;
  z-index: 1;
  width: 30%;
  margin: 0 65%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

button, .link-button {
  background-color: #796262;
  color: white;
  /* text-decoration-color: #bb509b; */
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-size: larger;
  border: none;
  border-radius: 5px;
  transition-duration: 0.4s;
}

.link-button {
  text-decoration: none;  
}

button:hover, .link-button:hover, .socialmedia:hover {
  /* background-color: #dac1f2; */
  /* background-color: #b39a90; */
  background-color: #dabfb5;
  cursor: pointer;
  box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
}

#menu-button,
#close-button {
  margin-top: 2%;
  margin-right: 6%;
  width: 150px;
}

#close-button:hover {
  background-color: rgb(244, 201, 201);
}

#nav ul {
  background-color: rgb(254, 250, 255);
  list-style-type: none;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 3%;
  width: 110px;
  transition-duration: 0.4s;
}

#nav ul li {
  margin: 4%;
}

#nav ul li a {
  text-decoration: none;
}

.current-page {
  font-weight: bolder;
}

p {
  font-size: 20px;
}

strong {
  font-weight: bold;
  color: #488297;
}

.pink-emphasis {
  color: #d35586;
}

.snd-emphasis {
  color: #85597f;
}

.bio {
  font-size: 20px;
}

#home-body {
  flex-basis: 65%;
}

#profile-picture {
  margin: auto;
}

.img-container {
  width: 75%;
  height: auto;
}

.about-gallery {
  margin: 5% auto;
}

.about-flex-container {
  margin: 0;
}

.about-flex-container h4{
  font-size: 20px;
}

.about-heading {
  font-weight: bold;
  color: #796262;
}

.about-text {
  flex-basis: 65%;
  margin-right: 15%;
}

.gallery-items {
  width: 100%;
  margin: 1%;
  display: flex;
  justify-content: center;
}

.about-photo,
.proj-info,
.gallery-item
 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.about-photo {
  align-items: center;
}

.gallery-photo {
  margin-top: 20px;
  max-height: 450px;
  display: flex;
  justify-content: center;
}

h3,
#contact h3 {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  font-weight: bolder;
  font-size: 25px;
  padding-left: 1%;
}

h4 {
  font-size: 22px;
  letter-spacing: 0.5px;
}

h5 {
  font-size: 16px;
  margin: 1%;
}

.exp-body {
  margin: auto auto 4% auto;
}

.exp-body ul {
  line-height: 1.3;
}

.proj-body {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.gallery-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.gallery-body button, .proj-body button {
  padding: 10px;
  margin: 5%;
}

.proj-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.view-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding: 0 0 1% 0;
}

.page-buttons {
  padding: 8px;
  font-size: 19px;
  background-color:#796262;
  color: white;
  border: none;
}

ul li {
  font-size: 20px;
}

a {
  color: #796262;
  /* padding: 10px; */
}

.gallery-item a {
  color: #030a24;
  text-decoration: none;
}

.link-no-padding {
  padding: 0;
  border-radius: 0;
}

.menu-item a:hover, .current-page a {
  background-color: #82665a;
  color: white;
  border-radius: 8px;
  /* font-weight: bold; */
}

#contact {
  margin: 5% 1%;
  padding: 2%;
  background-color: #fff9ed;
  font-size: 20px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  border: 1px solid;
  border-radius: 10px;
}

#contact p {
  padding: 0% 2%;
  font-size: 18px;
}

#contact ul {
  display: flex;
  flex-direction: column;
}

#contact ul li {
  flex-basis: 50%;
  padding-bottom: 1%;
  font-size: 18px;
}

#contact a {
  /* color:#6f5a5a; */
  /* font-weight: 500; */
  color: #796262;
}

#contact a:hover, .projects a:hover {
  font-weight: bold;
  background-color: transparent;
  /* color: #796262; */
  color: #826C7F;
}

.bio a:hover {
  color:#6c5656;
}

.gallery-resume {
  display: flex;
  flex-direction: column;
  margin: 3% 1%;
}

.proj-col {
  padding: 0 1%;
  margin: 1%;
}

.resume {
  flex-basis: 50%;
  padding: 0 1%;
}

img {
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
}

.home-resume {
  display: none;
}

#top-bar {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

#top-icon {
  margin-top: 0;
  float: left;
}

.socials {
  display: flex;
  align-items: center;
  gap: 15px;
}

.socials a {
  padding: 6px;
}


.hidden {
  display: none;
}

/* Wide-screen specific css */
@media (min-width: 850px) {
  .bio {
    font-size: 25px;
  }

  #top-bar {
    margin: 0 10%;
    align-items: center;
    margin-bottom: auto;
  }

  #nav {
    margin: 1.5% 0 1.5% auto;
    position: relative;
    background-color: transparent;
    width: auto;
  }

  #nav ul {
    flex-direction: row;
    justify-content: space-evenly;
    background-color: transparent;
    margin: 0.5% 4%;
    width: auto;
  }

  #nav ul li {
    margin: 1%;
  }

  #nav ul li a {
    margin-left: 60px;
    padding: 10px;
  }

  header {
    font-size: 50px;
  }

  .background {
    display: flex;
    flex-direction: row;
  }

  .pink-background {
    margin: 1% 10%;
  }

  .page-body {
    margin: 1% 10%;
  }

  .proj-col {
    margin: 0 10%;
  }

  .about-flex-container {
    margin: 0 2% 1% 2%;
  }

  .about-gallery {
    margin: 25px 10%;
  }

  .gallery-resume {
    display: flex;
    flex-direction: row;
    margin: 3% 1%;
  }

  .home-resume {
    display: inline-block;
  }

  #contact {
    margin: 2% 10%;
  }

  #contact p {
    font-size: 20px;
  }

  #contact ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  
  #contact ul li {
    flex-basis: 50%;
    padding-bottom: 1%;
    font-size: 20px;
  }

  #profile-picture {
    flex-basis: 35%;
    padding: 1% 0;
    margin-left: 5%;
  }

  .img-container {
    width: 100%;
    height: auto;
  }
}
